
import HomeComponent from './HomePageStyle';
import HomeSectionFifth from './HomeSections/HomeSectionFive';
import HomeSectionFour from './HomeSections/HomeSectionFour';
import HomeSectionThird from './HomeSections/HomeSectionThree';
import HomeSectionSecond from './HomeSections/HomeSectionTwo';
import HomeSectionFirst from './HomeSections/HomeSectionOne';
import { useEffect, useRef, useState } from 'react';
import HomePopup from './HomeSections/HomePopup';
import GetLandingPage from '@Components/Common/GetStartedProductPage/GetLandingPage';
import GetStartedFirst from '@Components/Common/GetStartedProductPage/GetStartedFirst';
import GetStartedSecond from '@Components/Common/GetStartedProductPage/GetStartedSecond';
import GetStartedThird from '@Components/Common/GetStartedProductPage/GetStartedThird';
import GetStartedFourth from '@Components/Common/GetStartedProductPage/GetStartedFourth';
import Cookie from 'js-cookie';
import NewGetStartedPage from "./HomeSections/NewGetStartedPage.style";
const Home = (props) => {

    const [urlChange, setUrlchange] = useState('');
    const [loginToken, setLoginToken] = useState();

    const { validReferralCheck, setValidReferralCheck, openForm, setOpenForm } = props
    const myRef = useRef();

    useEffect(() => {
        const token = localStorage.getItem("Token");
        setLoginToken(token)
    }, [])

    useEffect(() => {
        const getValue = localStorage.getItem('popup_not_display');
        if (getValue === 'true') {
            document.body.classList.remove('professional_retailer_form_popup');
            setOpenForm(false);

        } else {
            // setOpenForm(true);
            // document.body.classList.add('professional_retailer_form_popup');
        }
    }, [openForm])


    const PARENT_REFFERAL = Cookie.get('refferal-code')?.length > 0 ? Cookie.get('refferal-code') : null;


    function _onClickScroll() {
        myRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const LIST_TEXT = [
        "Healthy Body Mass Index (BMI) Report",
        "Heart Health Report",
        "Cognitive and Brain Function Report",
        "Detoxification Report",
        "Energy Report",
        "Gut/Microbiome Report",
        "Healthy Immune Response Report",
        "Healthy Inflammatory Response Report",
        "Methylation Report",
        "Mood Report"
    ];

    return (

        <>
            {(props?.landingPageData?.page === 'Personlized_landing_page' && PARENT_REFFERAL && !loginToken) ?
                <>
                    <NewGetStartedPage />
                    <div className='main_product_page_class'>
                        <div className='landing_page_section'><GetLandingPage {...props} /></div>
                        <div className='first_content_section'><GetStartedFirst /></div>
                        <div className='second_content_section'><GetStartedSecond list={LIST_TEXT} {...props} /></div>
                        <div className='Third_content_section'><GetStartedThird {...props} /></div>
                        <div className='fourth_content_section'><GetStartedFourth {...props} /></div>
                    </div>
                </>
                :
                <HomeComponent clickOn={urlChange}>
                    <div className="home-page">
                        <HomeSectionFirst />
                        <div className='left-img'><img src="/images/molecularleft1.png" /></div>
                        <HomeSectionSecond onClickScroll={() => _onClickScroll()} {...props} />
                        <div ref={myRef}>
                            <HomeSectionFour />
                        </div>
                        <div className='right-img'><img src="/images/molecular_right.png" /></div>
                        <HomeSectionFifth {...{ urlChange, setUrlchange }} />
                        {/*<div className='bottom-img'><img src="/images/molecule1.png" /></div>*/}
                        {/* <SvgAnimationBackground /> */}
                    </div>
                </HomeComponent>
            }

            <HomePopup openForm={openForm} setOpenForm={setOpenForm} validrefferel={validReferralCheck} setValidReferralCheck={setValidReferralCheck} />

        </>
    )
}
export default Home;


