import React, { useEffect, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'
import * as yup from 'yup';
import _ from 'lodash'
import DialogComponent from '@Components/Common/DialogComponent';
import { DialogSectionCss } from '@PagesComponent/ActiveSmartShip/ActiveStyleComp';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import api from '@api/Apis';

const HomePopupStyle = styled.div`
    position: fixed;
    z-index: 999; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    form.signupform.main-sign-frm {
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        position: absolute;
        height: 677px;
        padding: 0px;
        max-width: 677px;
        margin: 0;
        /* background-color: unset; */

        h2{
            color: #fff;
            text-align: center;
            margin-bottom: 30px;
            font-size: 28px;
        }
        button.MuiButtonBase-root {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 9;
            background: #fff;
            border-radius: 0;
                }
            svg.MuiSvgIcon-root {
            font-size: 25px;
            color:#fff;
            background-color: var(--green);
        } 
        .form-control,select#myId {
            line-height: normal;
            border-radius: 30px;
            width: 100%;
            height: 40px;
            border: 1px solid #b2b2b2;
            font-size: 16px;
            max-width: 100%;
            text-align: left;
            color: #999999;
        }
        button.btn-primary {
            font-size: 20px;
            color: #000000;
            font-weight: bold;
            font-family: "Exo";
            text-align: center;
            background: #fff;
            border-radius: 30px;
            width: 100%;
            max-width: 220px;
            margin: 20px auto 0;
            text-transform: uppercase;
            display: flex;
            font-weight: 600;
            justify-content: center;
        }

        .signup-news {
            height: 100%;
        }
    }   
`;

function useOutsideAlerter(ref, callBack) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callBack();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {

            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


const HomePopup = (props) => {
    const { openForm, setOpenForm } = props;
    const [newsLetter, setNewsLetter] = useState();
    const [successMsg, setSuccessMsg] = useState('');
    const { validrefferel, setValidReferralCheck } = props



    const wrapperRef = useRef(null);

    function _handleClose() {
        document.body.classList.remove('professional_retailer_form_popup');
        localStorage.setItem('popup_not_display', 'true');
        setOpenForm(false);
        setValidReferralCheck(true)
    }

    useOutsideAlerter(wrapperRef, () => {
        _handleClose()
    });

    const schema = yup.object().shape({
        first_name: yup.string().trim()
            .required('this field is required'),
        // .min(3, 'Enter minimum 3 character'),
        last_name: yup.string().trim()
            .required('this field is required'),
        // .min(3, 'Enter minimum 3 character'),
        email: yup.string().trim()
            .required('this field is required')
            .email('E-mail is required'),

    })

    const { register, handleSubmit, errors, reset } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    function _InputField(fieldName, placeHolder, label = null) {
        return (
            <div className="mb-3 field-class">
                {label && <label htmlFor={fieldName}>{label}</label>}
                <input type="text" className="form-control" name={fieldName} placeholder={placeHolder} ref={register} />
                {errors[fieldName] && <span className="error">{errors[fieldName]?.message}</span>}
            </div>
        )
    }

    async function onSubmit(data) {
        await api.newsletter(data).then((res) => {
            if (res?.data?.code === 1) {
                setNewsLetter(res?.data)
                setSuccessMsg(res?.data?.message);
                reset();
                setTimeout(() => {
                    setSuccessMsg('')
                    document.body.classList.remove('professional_retailer_form_popup');
                    localStorage.setItem('popup_not_display', 'true');
                    setOpenForm(false);
                }, 2000);
            }
        })
    }

    return (
        <>
            {openForm &&
                <HomePopupStyle>
                    <form className="signupform main-sign-frm" ref={wrapperRef}>
                        <IconButton onClick={() => _handleClose()}><Close /></IconButton>
                        <div className='signup-news'>
                            <iframe
                                src="https://link.goagency.com/widget/form/2LymIDyg6cndyTgYJNbU"
                                style={{ display: "block", width: "100%", height: "100%", border: "none", borderRadius: "4px" }}
                                id="popup-2LymIDyg6cndyTgYJNbU"
                                data-layout="{'id':'POPUP'}"
                                data-trigger-type="alwaysShow"
                                data-trigger-value=""
                                data-activation-type="alwaysActivated"
                                data-activation-value=""
                                data-deactivation-type="neverDeactivate"
                                data-deactivation-value=""
                                data-form-name="The DNA Code to Optimal Nutrition Form"
                                data-height="609"
                                data-layout-iframe-id="popup-2LymIDyg6cndyTgYJNbU"
                                data-form-id="2LymIDyg6cndyTgYJNbU"
                                title="The DNA Code to Optimal Nutrition Form"
                            ></iframe>
                        </div>
                    </form>
                </HomePopupStyle>
            }
        </>
    )
}

export default HomePopup;