import 'bootstrap/dist/css/bootstrap.min.css'
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Home from './Components/Home';

export default function Index(props) {
  const route = useRouter();
  useEffect(() => {
    // props?.setInnerLoader(true)

  }, [])

  return (
    <>
      <Head>
        <title>SNiP Nutrigenomics</title>
        <meta property="og:title" content="DNA-Tailored Nutrition for Whole Body Health" />
      </Head>

      <Home {...props} />
    </>
  )
}
