import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash';
import { LinkAnchor } from '@Components/Common/CommonMethods';
import DateFnsUtils from '@date-io/date-fns';
import { useRouter } from 'next/router';
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
function HomeSectionThree(props) {
    const router = useRouter();
    const FIRST_SIX = [
        { icon: "health_icon.png", text: "Healthy BMI " },
        { icon: "cardio_vas.png", text: "Heart Health" },
        { icon: "brain.png", text: "Cognitive and Brain Function" },
        { icon: "detox.png", text: "Detoxification" },
        { icon: "energy.png", text: "Energy" },
    ];
    const SECOND_FIVE = [
        { icon: "gut.png", text: "Gut/Microbiome" },
        { icon: "healthy_imune.png", text: "Healthy Immune Response" },
        { icon: "Inflammatory.png", text: "Healthy Inflammatory Response" },
        { icon: "methylation.png", text: "Methylation" },
        { icon: "mood.png", text: "Mood" },
        // { icon: "Personalized.png", text: "Your Personalized Supplement (CODE Complex™)" }
    ];

    return (
        <div className='home_third_section right-img-wrap'>
            {router?.pathname === "/get_started" &&
                <>
                    <div className='right-img'><img src="/images/molecular_right.png" /></div>
                    <div className='left-img'><img src="/images/molecularleft.png" /></div>
                </>
            }
            {/*<div className='first_container'>
                <h3>Are you a Wellness professional?</h3>
                <p><button onClick={() => router.push("/us/wellness_professionals")}>learn more</button></p>
    </div>*/}
            <div className='container'>
                <div className='second_container'>
                    {/* <div className='title_Section'>
                        <h2>Information that’s actionable.</h2>
                        <h3>Get 11 DNA reports containing more than insights.</h3>
                    </div>
                    <div className='text_section'>
                        <p>
                            Genes play many roles. Your SNiP DNA Reports show you exactly how the genes we test for can
                            influence your health in key areas from weight to energy, and brain function to a healthy immune response.
                        </p>
                        <p>
                            At SNiP, we go further than facts. Your reports include actionable information that’s personalized just for
                            you. Your reports show you what you need to know about you…and outline simple, clear, easy-to-follow
                            recommendations so you can take action for healthy aging, weight management and more.
                        </p>
                    </div> */}
                    <div className='Backend_section section-all'>
                        <div className='new_titles'>
                            <h2 style={{ color: "#000" }}>All-in-one Health Support <br /> in 10+ key areas.</h2>
                        </div>
                        {/* <div className='backend_titles'>
                            <h3>Backed by peer-reviewed science.<span>Written and reviewed by clinicians.</span></h3>
                        </div> */}
                        {/* <div className='dna_reports'>
                            <h3>Your SN<span>i</span>P DNA reports <br />help you understand:</h3>
                            <ol type="1">
                                <li>What your body needs</li>
                                <li>Why your body needs it</li>
                                <li>What you can do about it</li>
                            </ol>
                        </div> */}
                        <div className='icons_content'>
                            <div className='first_six'>
                                {_.map(FIRST_SIX, ({ icon, text }, index) => (
                                    <div key={index} className='iconContainer'>
                                        <span> <img src={`/images/${icon}`} /></span>
                                        <p>{text}</p>
                                    </div>
                                ))}
                                {_.map(SECOND_FIVE, ({ icon, text }, index) => (
                                    <div key={index} className='iconContainer'>
                                        <span><img src={`/images/${icon}`} /></span>
                                        <p>{text}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/*router?.pathname === "/landing_page" &&
                            <div className='right-img'><img src="/images/molecularleft1.png" /></div>
                                */}
                        {router?.pathname !== "/get_started" &&
                            <div className=''>
                                <LinkAnchor className="get-yours-third" href="/get_started/">Get Your DNA Test Kit
                                </LinkAnchor>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

HomeSectionThree.propTypes = {}

export default HomeSectionThree;