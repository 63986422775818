import styled from "styled-components";

const OuterTableStyle = styled.div`
  padding: 0px 40px;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  @media (max-width: 1199px) {
    overflow: auto;
  }
  && .MuiPaper-rounded {
    max-width: 100%;
    margin: 0px auto 0;
    .active_smartship_table_main_div {
      table {
        thead {
          color: #fff !important;
          font-weight: 700 !important;
          background: var(--site-purple);
          tr {
            th {
              font-size: 15px;
              padding: 15px;
              color: #fff !important;
              font-family: var(--common-font);
              font-weight: 600 !important;
            }
          }
        }
        tbody {
          &.active_amart_tbody {
            tr {
              &:nth-child(odd) {
                background: #eee;
              }
            }
          }
          tr {
            :hover {
              cursor: pointer;
              /* box-shadow: 0px 0px 2px 5px #dddd; */
              /* td {
                color: #fff !important;
              } */
              /* @media (max-width: 767px) {
                background: none;
                td {
                  color: #16356a !important; 
                }
              } */
            }

            td {
              font-size: 14px;
              padding: 15px;
              font-family: var(--common-font);
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1199px) {
    .active_smartship_table_main_div {
      overflow: auto;
    }
    .active_smartship_table_main_div table {
      width: 1400px;
    }
    ul.pagination {
      width: 100% !important;
      max-width: 473px;
      margin: 20px auto 0 !important;
    }
  }
  @media (max-width: 1024px) {
    ul.pagination {
      margin: 0px auto 40px !important;
    }
  }
  @media (max-width: 767px) {
    .active_smartship_table_main_div table {
      width: 100%;
    }
  }
  @media (max-width: 576px) {
    ul.pagination li a {
      font-size: 12px;
    }
    ul.pagination li {
      /* width: 17px !important;
      height: 17px !important; */
      line-height: 17px;
    }
    .heading-top-main h1 {
      margin: 0;
      padding: 24px 20px;
      font-size: 20px;
    }
    ul.pagination {
      max-width: 100%;
    }
  }
  td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.year_block1.css-1yhpg23-MuiTableCell-root{
    width: 23%;
  }
`;

export const DialogSectionCss = styled.div`
  [class*="ActiveStyleComp"] > .shipping_address {
    display: flex;
    align-items: center;
    gap: 10px;
    h5 {
      margin: 0;
    }
  }
  label {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
    text-decoration: underline;
  }

  .content_container {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    background: #f1f1f1;
    border-radius: 5px;
    padding: 0;
    & > * {
      flex: 100%;
      padding: 10px;
    }
    .shipping_address .billing_address,
    .shipping_address {
      display: flex;
      flex-direction: column;
      max-width: 45%;
      align-items: flex-start;
      h6 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
      span {
        word-break: break-all;
      }
    }
    .billing_address {
      h6 {
        font-size: 16px;
        margin: 0 0;
        font-weight: 600;
      }
    }
  }
  table {
    width: 100%;
    margin-top: 20px;
    tbody {
      border: 1px solid #ddd;
    }
    tr {
      td {
        padding: 12px 10px;
        text-align: center;
      }
      th {
        padding: 12px 10px;
        border: 1px solid #fff;
        text-align: center;
        color: #fff;
        background-color: #16356a;
      }
    }
  }
 
`;

export default OuterTableStyle;
