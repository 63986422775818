import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import VedioPlayer from '@Components/Common/VedioPlayer'
function HomeSectionOne(props) {

    const [state, setState] = useState(0);
    const [fadeProp, setFadeProp] = useState({ fade: "fade-in" })
    const TEXTS = [
        "healthier",
        "stronger",
        "refreshed",
        "rejuvenated",
        "energetic",
        "happier",
        "well rested",
        "brighter",
        "vibrant",
        "resilient",
        "more active"
    ];
    useEffect(() => {
        const interval = setInterval(() => {
            if (fadeProp.fade === 'fade-in') {
                setFadeProp({
                    fade: 'fade-out'
                })
            } else {
                setState(index => index + 1)
                setFadeProp({
                    fade: 'fade-in'
                })
            }
        }, 1500);
        return () => clearInterval(interval);
    }, [fadeProp]);

    return (
        <div className='home_first_section'>
            <div className='nutrtion-section' style={{ width: "100%" }}>
                <h2 style={{ color: "#fff" }}>SIMPLY. GENIUS.</h2><h2>DNA-PERSONALIZED NUTRITIONAL SUPPLEMENTS.</h2>
            </div>
            <div className='video_sec'>
                <VedioPlayer url={"https://player.vimeo.com/video/798582440?autoplay=false&loop=0&autopause=0&muted=0"} />
                <img src="/images/snip_video_thumbnail_new.jpg" />
            </div>
            <div className='content-section-main'>
                <div className='content-section'>
                    {     /*               <h3>SIMPLY. GENIUS.<span>DNA-Personalized Nutrition.</span></h3>
    */}                    <div className='crack'>Cracking the code for a
                        <strong className={fadeProp.fade}> {TEXTS[state % TEXTS.length]}</strong> you</div>
                    <p className='finger'>Like fingerprints, your genes are what make you, you.</p>
                    <p>
                        We custom create supplements based on your body’s unique genetic code, making it easier for you to
                        absorb and utilize essential nutrients. </p>
                    <p>
                        Why is this important? Because vitamins, minerals and other key nutrients give your cells the energy
                        they need to grow and thrive. Without the proper nutrition, it’s harder for your body to function optimally.
                    </p>
                </div>
            </div>
        </div>
    )
}

HomeSectionOne.propTypes = {}

export default HomeSectionOne